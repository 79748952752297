export function PraxisLightIcon() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 200.000000 200.000000"
      preserveAspectRatio="xMidYMid meet">
      <g
        transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none">
        <path
          d="M0 1000 l0 -1000 1000 0 1000 0 0 1000 0 1000 -1000 0 -1000 0 0
    -1000z m785 385 c111 -54 165 -162 165 -331 l0 -94 55 0 55 0 0 108 c0 132 16
    184 75 250 142 158 389 124 482 -66 24 -48 28 -69 27 -137 0 -92 -21 -143 -83
    -208 -64 -68 -100 -81 -238 -85 l-123 -4 0 -114 0 -114 -70 0 -69 0 -3 113 -3
    112 -52 3 -53 3 0 -115 0 -116 -70 0 -69 0 -3 113 -3 112 -120 5 c-139 6 -174
    19 -246 90 -57 57 -82 126 -81 219 4 219 228 354 427 256z"
        />
        <path
          d="M584 1265 c-90 -40 -117 -167 -53 -249 36 -46 69 -56 179 -56 l100 0
    0 83 c0 100 -13 150 -49 187 -47 50 -113 63 -177 35z"
        />
        <path
          d="M1280 1259 c-65 -38 -75 -61 -78 -186 l-4 -113 100 0 c86 0 103 3
    132 23 57 38 75 71 75 136 0 48 -5 64 -29 95 -50 65 -130 84 -196 45z"
        />
      </g>
    </svg>
  );
}
