export function GitLabLightIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.2511 6.74928L14.2335 6.70452L12.5379 2.27912C12.5034 2.19238 12.4423 2.1188 12.3634 2.06894C12.2844 2.01992 12.1923 1.99632 12.0995 2.0013C12.0067 2.00629 11.9177 2.03964 11.8444 2.09683C11.772 2.15567 11.7194 2.2354 11.6939 2.32517L10.549 5.82813H5.91272L4.76778 2.32517C4.74294 2.23491 4.69028 2.15479 4.61728 2.09618C4.54403 2.03899 4.45499 2.00564 4.36219 2.00065C4.26938 1.99567 4.17728 2.01927 4.09832 2.06829C4.01959 2.11836 3.95855 2.19187 3.92382 2.27847L2.22489 6.70193L2.20802 6.74669C1.96392 7.38449 1.93379 8.08436 2.12217 8.74078C2.31056 9.3972 2.70724 9.97458 3.25242 10.3859L3.25826 10.3904L3.27383 10.4014L5.85694 12.3358L7.13487 13.3031L7.9133 13.8908C8.00436 13.9599 8.11554 13.9973 8.22987 13.9973C8.34419 13.9973 8.45538 13.9599 8.54643 13.8908L9.32487 13.3031L10.6028 12.3358L13.2015 10.3898L13.208 10.3846C13.7519 9.97321 14.1477 9.39642 14.3358 8.74089C14.5239 8.08535 14.4941 7.38648 14.2511 6.74928Z"
        fill="#E24329"
      />
      <path
        d="M14.2406 6.74935L14.2231 6.70459C13.3968 6.87419 12.6182 7.22417 11.9429 7.72953L8.21875 10.5455C9.48695 11.5049 10.591 12.3385 10.591 12.3385L13.1897 10.3924L13.1962 10.3872C13.7409 9.97589 14.1373 9.39873 14.3257 8.74263C14.514 8.08654 14.4842 7.38701 14.2406 6.74935Z"
        fill="#FC6D26"
      />
      <path
        d="M5.85156 12.3384L7.12949 13.3056L7.90793 13.8933C7.99898 13.9625 8.11017 13.9999 8.22449 13.9999C8.33882 13.9999 8.45 13.9625 8.54106 13.8933L9.31949 13.3056L10.5974 12.3384C10.5974 12.3384 9.49204 11.5022 8.22384 10.5454C6.95564 11.5022 5.85156 12.3384 5.85156 12.3384Z"
        fill="#FCA326"
      />
      <path
        d="M4.5044 7.72944C3.82963 7.22303 3.0512 6.87214 2.22489 6.7019L2.20802 6.74666C1.96392 7.38447 1.93379 8.08434 2.12217 8.74076C2.31056 9.39718 2.70724 9.97456 3.25242 10.3858L3.25826 10.3904L3.27383 10.4014L5.85694 12.3358C5.85694 12.3358 6.95972 11.5023 8.22922 10.5428L4.5044 7.72944Z"
        fill="#FC6D26"
      />
    </svg>
  );
}
