export function TwitchLightIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_630_83955)">
        <path
          d="M18.25 11.3571L15.75 13.9285H13.25L11.0625 16.1785V13.9285H8.25V4.28564H18.25V11.3571Z"
          fill="white"
        />
        <path
          d="M7.625 3L4.5 6.21429V17.7857H8.25V21L11.375 17.7857H13.875L19.5 12V3H7.625ZM18.25 11.3571L15.75 13.9286H13.25L11.0625 16.1786V13.9286H8.25V4.28571H18.25V11.3571Z"
          fill="#9146FF"
        />
        <path d="M16.375 6.53564H15.125V10.3928H16.375V6.53564Z" fill="#9146FF" />
        <path d="M12.9375 6.53564H11.6875V10.3928H12.9375V6.53564Z" fill="#9146FF" />
      </g>
      <defs>
        <clipPath id="clip0_630_83955">
          <rect width="15" height="18" fill="white" transform="translate(4.5 3)" />
        </clipPath>
      </defs>
    </svg>
  );
}
