export function SlackLightIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.65138 13.979C7.65138 14.8462 6.94293 15.5547 6.07569 15.5547C5.20845 15.5547 4.5 14.8462 4.5 13.979C4.5 13.1118 5.20845 12.4033 6.07569 12.4033H7.65138V13.979Z"
        fill="#E01E5A"
      />
      <path
        d="M8.45312 13.979C8.45312 13.1118 9.16157 12.4033 10.0288 12.4033C10.8961 12.4033 11.6045 13.1118 11.6045 13.979V17.9243C11.6045 18.7916 10.8961 19.5 10.0288 19.5C9.16157 19.5 8.45312 18.7916 8.45312 17.9243V13.979Z"
        fill="#E01E5A"
      />
      <path
        d="M10.0288 7.65138C9.16157 7.65138 8.45312 6.94293 8.45312 6.07569C8.45312 5.20845 9.16157 4.5 10.0288 4.5C10.8961 4.5 11.6045 5.20845 11.6045 6.07569V7.65138H10.0288Z"
        fill="#36C5F0"
      />
      <path
        d="M10.021 8.44531C10.8883 8.44531 11.5967 9.15376 11.5967 10.021C11.5967 10.8882 10.8883 11.5967 10.021 11.5967H6.07569C5.20845 11.5967 4.5 10.8882 4.5 10.021C4.5 9.15376 5.20845 8.44531 6.07569 8.44531H10.021Z"
        fill="#36C5F0"
      />
      <path
        d="M16.3516 10.0212C16.3516 9.15401 17.06 8.44556 17.9273 8.44556C18.7945 8.44556 19.5029 9.15401 19.5029 10.0212C19.5029 10.8885 18.7945 11.5969 17.9273 11.5969H16.3516V10.0212Z"
        fill="#2EB67D"
      />
      <path
        d="M15.5498 10.021C15.5498 10.8883 14.8414 11.5967 13.9741 11.5967C13.1069 11.5967 12.3984 10.8883 12.3984 10.021V6.07569C12.3984 5.20845 13.1069 4.5 13.9741 4.5C14.8414 4.5 15.5498 5.20845 15.5498 6.07569V10.021Z"
        fill="#2EB67D"
      />
      <path
        d="M13.9741 16.3489C14.8414 16.3489 15.5498 17.0573 15.5498 17.9246C15.5498 18.7918 14.8414 19.5003 13.9741 19.5003C13.1069 19.5003 12.3984 18.7918 12.3984 17.9246V16.3489H13.9741Z"
        fill="#ECB22E"
      />
      <path
        d="M13.9741 15.5547C13.1069 15.5547 12.3984 14.8462 12.3984 13.979C12.3984 13.1118 13.1069 12.4033 13.9741 12.4033H17.9195C18.7867 12.4033 19.4951 13.1118 19.4951 13.979C19.4951 14.8462 18.7867 15.5547 17.9195 15.5547H13.9741Z"
        fill="#ECB22E"
      />
    </svg>
  );
}
