export function PylonLightIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_630_84073)">
        <path
          d="M18.5 3H5.5C4.11929 3 3 4.11929 3 5.5V18.5C3 19.8807 4.11929 21 5.5 21H18.5C19.8807 21 21 19.8807 21 18.5V5.5C21 4.11929 19.8807 3 18.5 3Z"
          fill="#4F46E5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 18.25C15.4517 18.25 18.25 15.4517 18.25 12C18.25 8.54825 15.4517 5.75 12 5.75C8.54825 5.75 5.75 8.54825 5.75 12C5.75 15.4517 8.54825 18.25 12 18.25ZM12.7812 16.6228C14.9983 16.2508 16.6875 14.3228 16.6875 12C16.6875 9.67738 14.9983 7.74925 12.7812 7.37738V16.6228ZM11.2188 16.6228V15.3854H8.75787C9.43529 16.0356 10.2928 16.4668 11.2188 16.6228ZM11.2188 13.8229V12.7812H7.37738C7.43734 13.1391 7.53894 13.4887 7.68013 13.8229H11.2188ZM11.2188 11.2188V10.1771H7.68013C7.53894 10.5113 7.43734 10.8609 7.37738 11.2188H11.2188ZM11.2188 8.61462V7.37738C10.2928 7.53325 9.43527 7.96439 8.75787 8.61462H11.2188Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_630_84073">
          <rect width="18" height="18" fill="white" transform="translate(3 3)" />
        </clipPath>
      </defs>
    </svg>
  );
}
