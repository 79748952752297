export function WixLightIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_630_83944)">
        <mask
          id="mask0_630_83944"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="7"
          width="24"
          height="10">
          <path d="M23.8995 7.5H0V16.5H23.8995V7.5Z" fill="white" />
        </mask>
        <g mask="url(#mask0_630_83944)">
          <path
            d="M23.8988 7.5H22.5787C22.2125 7.5 21.8703 7.67639 21.6645 7.97137L19.8991 10.5046C19.8519 10.5718 19.75 10.5718 19.703 10.5046L17.9374 7.97137C17.7321 7.67639 17.3895 7.5 17.0233 7.5H15.7031L18.8483 12.0126L15.7205 16.5H17.0406C17.4068 16.5 17.7492 16.3236 17.9549 16.0286L19.703 13.5205C19.75 13.4533 19.8519 13.4533 19.8991 13.5205L21.6472 16.0286C21.8525 16.3236 22.1951 16.5 22.5613 16.5H23.8815L20.7538 12.0126L23.8988 7.5Z"
            fill="black"
          />
          <path
            d="M12.9844 8.4206V16.5H13.6153C14.1382 16.5 14.562 16.0879 14.562 15.5794V7.5H13.931C13.4081 7.5 12.9844 7.91208 12.9844 8.4206Z"
            fill="black"
          />
          <path
            d="M11.8305 7.5H11.269C10.6508 7.5 10.1152 7.91583 9.97819 8.50204L8.72209 13.8729L7.62266 8.85933C7.4193 7.93272 6.44182 7.30785 5.44157 7.57656C4.80444 7.7477 4.3321 8.27124 4.19433 8.89986L3.10611 13.8662L1.85231 8.50242C1.71493 7.91621 1.17931 7.5 0.561097 7.5H0L2.10431 16.4996H2.90157C3.62513 16.4996 4.25028 16.0076 4.40118 15.3193L5.74642 9.17908C5.76301 9.1029 5.83324 9.04772 5.91312 9.04772C5.993 9.04772 6.06324 9.1029 6.07984 9.17908L7.42624 15.3197C7.57712 16.008 8.20227 16.4996 8.92585 16.4996H9.72581L11.8305 7.5Z"
            fill="black"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_630_83944">
          <rect width="24" height="9" fill="white" transform="translate(0 7.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
