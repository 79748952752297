export function FiservLightIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_630_84101)">
        <mask
          id="mask0_630_84101"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="-19"
          y="-180"
          width="202"
          height="261">
          <path d="M-18.6016 80.4116H182.905V-179.199H-18.6016V80.4116Z" fill="white" />
        </mask>
        <g mask="url(#mask0_630_84101)">
          <path
            d="M14.4765 6.01139C14.4765 6.06155 14.4376 6.0999 14.3876 6.0999C14.3369 6.0999 14.2984 6.06155 14.2984 6.01139C14.2984 5.96092 14.3369 5.92289 14.3876 5.92289C14.4376 5.92289 14.4765 5.96092 14.4765 6.01139ZM14.4936 6.01139C14.4936 5.95108 14.4472 5.90585 14.3876 5.90585C14.327 5.90585 14.2812 5.95108 14.2812 6.01139C14.2812 6.07138 14.327 6.11694 14.3876 6.11694C14.4472 6.11694 14.4936 6.07138 14.4936 6.01139ZM14.4357 5.98648C14.4357 5.96255 14.4186 5.95174 14.3978 5.95174H14.3471V6.06712H14.3669V6.02254H14.3889L14.4116 6.06712H14.435L14.4116 6.02057C14.4261 6.01598 14.4357 6.00484 14.4357 5.98648ZM14.4159 5.98714C14.4159 5.99828 14.408 6.00353 14.3971 6.00353H14.3669V5.97042H14.3971C14.408 5.97042 14.4159 5.97632 14.4159 5.98714Z"
            fill="#FF6600"
          />
        </g>
        <path d="M14.9952 10.0483H14.1016V10.9383H14.9952V10.0483Z" fill="#FF6600" />
        <path
          d="M12.2903 6.08595L12.8228 10.0634L13.3542 6.08595H14.1836L13.4078 10.9382H12.2284L11.4609 6.08595H12.2903Z"
          fill="#FF6600"
        />
        <mask
          id="mask1_630_84101"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="-19"
          y="-180"
          width="202"
          height="261">
          <path d="M-18.6016 80.4116H182.905V-179.199H-18.6016V80.4116Z" fill="white" />
        </mask>
        <g mask="url(#mask1_630_84101)">
          <path
            d="M1.45997 6.08595H1V6.73071H1.45997V10.9382H2.26831V6.73071H2.83628V6.08595H2.26831V5.09963C2.26831 4.95573 2.29827 4.84559 2.36807 4.7702C2.4382 4.69546 2.54521 4.66006 2.69042 4.66006H2.83595V3.99989H2.54357C2.18435 3.99989 1.83829 4.07561 1.67366 4.23131C1.50903 4.38701 1.45997 4.62859 1.45997 4.97277V6.08595Z"
            fill="#FF6600"
          />
        </g>
        <path d="M3.08594 10.938H3.89624V6.08569H3.08594V10.938Z" fill="#FF6600" />
        <mask
          id="mask2_630_84101"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="-19"
          y="-180"
          width="202"
          height="261">
          <path d="M-18.6016 80.4116H182.905V-179.199H-18.6016V80.4116Z" fill="white" />
        </mask>
        <g mask="url(#mask2_630_84101)">
          <path
            d="M7.63391 8.78685H9.32301V7.29147C9.32104 6.39693 8.88905 6.03013 8.07051 6.03013C7.21937 6.03013 6.82031 6.39594 6.82031 7.29737V9.75481C6.82295 10.5746 7.32144 10.9988 8.07018 10.9988C8.81859 10.9988 9.32104 10.6497 9.32301 9.75481V9.36605H8.54036L8.54135 9.8266C8.54135 10.155 8.41788 10.3822 8.08664 10.3822C7.75475 10.3822 7.63391 10.1531 7.63391 9.82627V8.78685ZM7.63391 7.13052C7.63391 6.85682 7.70536 6.57328 8.08434 6.57328C8.46299 6.57328 8.5397 6.85256 8.5397 7.1302V8.13291H7.63391V7.13052Z"
            fill="#FF6600"
          />
          <path
            d="M5.71715 8.17969C5.10242 7.64834 5.05468 7.57131 5.05468 7.17534C5.05468 6.83804 5.08398 6.63285 5.38855 6.63285C5.69278 6.63285 5.73229 6.83542 5.73229 7.17927V7.62736H6.5133V6.89639C6.51066 6.32243 6.06715 6.03069 5.37044 6.03069C4.68525 6.03069 4.23845 6.25785 4.23449 7.17534C4.23384 7.33137 4.23482 7.33137 4.23449 7.27105C4.23581 7.55951 4.23713 8.01678 4.95327 8.62844L5.15872 8.80348C5.68653 9.24665 5.74415 9.30008 5.74184 9.82717C5.74151 10.152 5.72143 10.3982 5.379 10.3982C5.03657 10.3982 5.01517 10.15 5.01517 9.99696V9.37613H4.19531V10.0166C4.19531 10.805 4.7106 11 5.37801 11C6.04608 11 6.56203 10.803 6.56203 9.78881V9.70457C6.56038 8.99589 6.43164 8.80577 5.71715 8.17969Z"
            fill="#FF6600"
          />
          <path
            d="M10.4104 6.58559V6.08603H9.67188V10.938H10.4687V7.5565C10.4684 7.29525 10.554 7.12316 10.7028 7.02155C10.8437 6.92485 11.0172 6.89207 11.2382 6.88879V6.03031C10.8154 6.03916 10.633 6.12209 10.4186 6.56788L10.4104 6.58559Z"
            fill="#FF6600"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_630_84101">
          <rect width="14" height="7" fill="white" transform="translate(1 4)" />
        </clipPath>
      </defs>
    </svg>
  );
}
