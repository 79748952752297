export function TreezorLightIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="16"
      height="16"
      viewBox="0 0 27 30">
      {' '}
      <defs>
        {' '}
        <linearGradient id="a" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
          {' '}
          <stop offset="0" stopColor="#304053"></stop> <stop offset="1" stopColor="#152438"></stop>{' '}
        </linearGradient>{' '}
      </defs>{' '}
      <g transform="translate(-101.47 -47.391)">
        {' '}
        <path
          className="tree"
          d="M26.523,7.881A4.555,4.555,0,0,0,22.9,6.518c-.142-.137.147.127,0,0A9.345,9.345,0,0,0,14.1,0,8.926,8.926,0,0,0,5.153,7.619,6.145,6.145,0,0,0,1.992,8.944c-2.683,2.008-2.767,7.545.43,11.415s8.8,4.145,11.149,2.6A4.291,4.291,0,0,0,14.95,21.4a9,9,0,0,0,8.408.339c2.86-1.455,3.977-5.12,3.3-8.712a4.084,4.084,0,0,0-.137-5.146"
          transform="translate(101.969 47.891)"
          fill="#2376e5"
          stroke="#2376e5"
          strokeWidth="1"></path>{' '}
        <path
          className="tree trunk"
          d="M28.957,48h0A1.166,1.166,0,0,1,27.8,46.681l.29-2.123a.877.877,0,0,1,1.736,0l.29,2.123A1.166,1.166,0,0,1,28.957,48"
          transform="translate(88.397 26.443)"
          fill="url(#a)"></path>{' '}
      </g>{' '}
    </svg>
  );
}
