export function ThreeDEyeLightIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 12 13" fill="none">
      <path
        d="M6.00015 0C2.67534 0 0 2.75645 0 6.18106C0 9.60567 2.67564 12.3621 6.00015 12.3621C9.32435 12.3621 12 9.60567 12 6.18106C12 2.75645 9.32466 0 6.00015 0ZM6.00015 11.6104C3.08118 11.6104 0.729667 9.18807 0.729667 6.18106C0.729667 3.17405 3.08148 0.751677 6.00015 0.751677C8.91882 0.751677 11.2703 3.17405 11.2703 6.18106C11.2703 9.18807 8.91912 11.6104 6.00015 11.6104Z"
        fill="#46BBA2"
      />
      <path
        d="M5.95508 1.67041C3.56317 1.67041 1.61719 3.67549 1.61719 6.18108C1.61719 8.64506 3.56317 10.6917 5.99549 10.6917C8.38741 10.6917 10.3738 8.68667 10.3738 6.18108C10.3334 3.67549 8.38741 1.67041 5.95508 1.67041ZM6.92807 9.06266C5.79287 9.06266 4.86028 8.10233 4.86028 6.93275C4.86028 5.76348 5.79287 4.80285 6.92807 4.80285C8.06298 4.80285 8.99556 5.76348 8.99556 6.93275C8.99556 8.10233 8.06298 9.06266 6.92807 9.06266Z"
        fill="#46BBA2"
      />
    </svg>
  );
}

export function ThreeDEyeDarkIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 12 13" fill="none">
      <path
        d="M6.00015 0C2.67534 0 0 2.75645 0 6.18106C0 9.60567 2.67564 12.3621 6.00015 12.3621C9.32435 12.3621 12 9.60567 12 6.18106C12 2.75645 9.32466 0 6.00015 0ZM6.00015 11.6104C3.08118 11.6104 0.729667 9.18807 0.729667 6.18106C0.729667 3.17405 3.08148 0.751677 6.00015 0.751677C8.91882 0.751677 11.2703 3.17405 11.2703 6.18106C11.2703 9.18807 8.91912 11.6104 6.00015 11.6104Z"
        fill="#46BBA2"
      />
      <path
        d="M5.95508 1.67041C3.56317 1.67041 1.61719 3.67549 1.61719 6.18108C1.61719 8.64506 3.56317 10.6917 5.99549 10.6917C8.38741 10.6917 10.3738 8.68667 10.3738 6.18108C10.3334 3.67549 8.38741 1.67041 5.95508 1.67041ZM6.92807 9.06266C5.79287 9.06266 4.86028 8.10233 4.86028 6.93275C4.86028 5.76348 5.79287 4.80285 6.92807 4.80285C8.06298 4.80285 8.99556 5.76348 8.99556 6.93275C8.99556 8.10233 8.06298 9.06266 6.92807 9.06266Z"
        fill="#46BBA2"
      />
    </svg>
  );
}
