export function FrontappLightIcon() {
  return (
    <svg width="12" height="12" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M0 63.6152H21.3918V21.3008H64.0428V-0.00341797H0V63.6152Z" fill="#A857F1"></path>
        <path
          d="M45.5945 64.8878C56.2523 64.8878 64.8922 56.2479 64.8922 45.5901C64.8922 34.9323 56.2523 26.2925 45.5945 26.2925C34.9367 26.2925 26.2969 34.9323 26.2969 45.5901C26.2969 56.2479 34.9367 64.8878 45.5945 64.8878Z"
          fill="#A857F1"></path>
      </g>
    </svg>
  );
}
