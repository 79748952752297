import styled, { css } from 'styled-components';

import { Div, StyledUtilsProps } from '../helpers/StyledUtils';

const StyledDivider = styled(Div)<{ vertical?: boolean }>`
  background-color: ${({ theme }) => theme.colors.outline.neutral};
  ${({ vertical }) =>
    vertical
      ? css`
          min-width: 1px;
          align-self: stretch;
        `
      : css`
          min-height: 1px;
          width: 100%;
        `}
`;

interface Props extends StyledUtilsProps {
  vertical?: boolean;
}

const Divider: React.FC<Props> = ({ ...props }) => {
  return <StyledDivider {...props} />;
};

export default Divider;
