export function SolidgateLightIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V5Z"
        fill="#4DF987"
      />
      <path d="M14.25 9.75H6.9375V17.0625H14.25V9.75Z" fill="black" />
      <path d="M17.0625 6.9375H9.75V14.25H17.0625V6.9375Z" fill="black" />
      <path d="M14.25 9.75H9.75V14.25H14.25V9.75Z" fill="#4DF987" />
    </svg>
  );
}
