export function BondsmithLightIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      width="16"
      height="16"
      viewBox="0 0 256.000000 256.000000"
      preserveAspectRatio="xMidYMid meet">
      <g
        transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
        fill="#4862f4"
        stroke="none">
        <path d="M515 2111 c-22 -10 -52 -31 -67 -47 -60 -64 -58 -37 -58 -821 0 -678 1 -720 19 -758 22 -47 74 -99 114 -114 19 -7 235 -11 647 -11 587 0 621 1 682 20 120 37 216 121 271 238 30 63 32 74 32 182 0 109 -2 119 -32 183 -59 124 -177 221 -304 248 l-54 12 60 16 c134 37 240 125 298 248 30 64 32 74 32 183 0 108 -2 119 -31 180 -57 120 -152 203 -272 240 -61 19 -95 20 -681 20 -578 0 -618 -2 -656 -19z m815 -630 c35 -69 116 -148 192 -186 39 -19 80 -35 92 -36 11 0 30 -4 41 -8 16 -7 9 -10 -33 -20 -162 -34 -296 -171 -334 -340 l-12 -54 -13 56 c-18 83 -50 143 -109 206 -59 64 -131 107 -219 132 l-60 16 43 8 c75 12 175 70 235 134 63 68 92 124 109 204 l12 58 18 -66 c10 -37 27 -84 38 -104z" />
      </g>
    </svg>
  );
}
