export function SvixLightIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_630_83976)">
        <path
          d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
          fill="white"
        />
        <path
          d="M17.3113 10.6771C15.8174 10.6045 14.422 9.7849 13.7736 8.34283C13.4883 7.70479 12.8502 7.28461 12.1499 7.26387C10.6664 7.22237 9.72745 9.08461 10.6612 10.2518C10.9828 10.6564 11.46 10.8794 12.0877 10.9209C13.3015 11.0091 14.3442 11.533 15.0341 12.3993C16.2998 13.9918 16.1182 16.3987 14.6502 17.7941C12.8813 19.48 9.92457 19.1325 8.57587 17.1094C8.45656 16.9278 8.35281 16.7411 8.25944 16.544C7.97414 15.9059 7.3361 15.4858 6.63581 15.465C5.91477 15.4443 5.23524 15.7192 4.80469 16.1809C6.22083 18.6137 8.83524 20.2633 11.8543 20.3152C16.4502 20.393 20.2422 16.7359 20.32 12.1399C20.3355 11.367 20.237 10.6149 20.0554 9.89902C20.0917 10.0495 18.8986 10.4644 18.769 10.5008C18.2917 10.6408 17.7989 10.6927 17.3165 10.6719L17.3113 10.6771Z"
          fill="#2C70FF"
        />
        <path
          d="M6.68993 13.3072C8.215 13.3435 9.60002 14.2668 10.2277 15.657C10.513 16.2951 11.151 16.7152 11.8513 16.736C12.3389 16.7515 12.8006 16.57 13.1585 16.2328C13.8225 15.6051 13.9107 14.4639 13.3401 13.7429C13.0185 13.3383 12.5412 13.1152 11.9136 13.0737C10.6997 12.9856 9.65708 12.4616 8.96717 11.5954C7.43172 9.66567 8.08532 6.79708 10.1966 5.60918C10.8087 5.26682 11.5038 5.08008 12.2041 5.10083C13.7291 5.13714 15.1141 6.06048 15.7418 7.45068C16.0271 8.08872 16.6651 8.5089 17.3654 8.52964C18.0917 8.55039 18.766 8.27547 19.1966 7.81379C17.7804 5.38094 15.166 3.73137 12.147 3.67431C7.55103 3.5965 3.76428 7.25875 3.68129 11.8495C3.66573 12.6224 3.76428 13.3746 3.95103 14.0905C4.72394 13.5665 5.67841 13.2812 6.69512 13.302L6.68993 13.3072Z"
          fill="#2C70FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_630_83976">
          <rect width="18" height="18" fill="white" transform="translate(3 3)" />
        </clipPath>
      </defs>
    </svg>
  );
}
