export function RazorpayLightIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.6996 8.34325L9.89844 11.2871L14.4844 8.32649L11.4851 19.4974L14.5312 19.5L18.9619 3"
        fill="#3395FF"
      />
      <path
        d="M5.76158 14.8038L4.5 19.5001H10.7433L13.298 9.94653L5.76158 14.8038Z"
        fill="#072654"
      />
    </svg>
  );
}
