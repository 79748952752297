export function GitHubLightIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_630_84144)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.98212 2C4.67416 2 2 4.75 2 8.15212C2 10.8716 3.71343 13.1736 6.09041 13.9884C6.38759 14.0496 6.49645 13.856 6.49645 13.6931C6.49645 13.5505 6.48665 13.0616 6.48665 12.5522C4.82257 12.919 4.47604 11.8189 4.47604 11.8189C4.20861 11.1059 3.81237 10.9226 3.81237 10.9226C3.26771 10.5457 3.85204 10.5457 3.85204 10.5457C4.4562 10.5865 4.77322 11.1772 4.77322 11.1772C5.30796 12.1143 6.16963 11.8495 6.51629 11.6865C6.56575 11.2892 6.72433 11.0142 6.89269 10.8615C5.56547 10.7189 4.16906 10.1892 4.16906 7.82613C4.16906 7.15388 4.40661 6.60388 4.78302 6.17613C4.72363 6.02338 4.51559 5.39175 4.84253 4.54638C4.84253 4.54638 5.34763 4.38338 6.48653 5.17788C6.97413 5.04321 7.47699 4.9747 7.98212 4.97412C8.48722 4.97412 9.00212 5.0455 9.47759 5.17788C10.6166 4.38338 11.1217 4.54638 11.1217 4.54638C11.4487 5.39175 11.2405 6.02338 11.1811 6.17613C11.5674 6.60388 11.7952 7.15388 11.7952 7.82613C11.7952 10.1892 10.3988 10.7086 9.06163 10.8615C9.27959 11.055 9.46767 11.4216 9.46767 12.0022C9.46767 12.8272 9.45788 13.4894 9.45788 13.693C9.45788 13.856 9.56686 14.0496 9.86392 13.9885C12.2409 13.1735 13.9543 10.8716 13.9543 8.15212C13.9641 4.75 11.2802 2 7.98212 2Z"
          fill="#24292F"
        />
      </g>
      <defs>
        <clipPath id="clip0_630_84144">
          <rect width="12" height="12" fill="white" transform="translate(2 2)" />
        </clipPath>
      </defs>
    </svg>
  );
}
