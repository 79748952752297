export function CustomerioLightIcon() {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 48 48">
      <path
        d="M0 0 C15.84 0 31.68 0 48 0 C48 15.84 48 31.68 48 48 C32.16 48 16.32 48 0 48 C0 32.16 0 16.32 0 0 Z "
        fill="#0D373C"
        transform="translate(0,0)"
      />
      <path
        d="M0 0 C7.26153846 -0.61538462 7.26153846 -0.61538462 11 2.5 C11.66 3.325 12.32 4.15 13 5 C13.33 3.35 13.66 1.7 14 0 C18.42241976 -0.4574917 21.8307159 -0.75635217 25.5 2 C29.38190917 6.43646763 28.93169313 12.67610245 28.578125 18.25 C27.9299037 22.45467874 27.26896334 24.67116151 23.8671875 27.2265625 C20.46745749 28.63481886 17.63333576 28.37586232 14 28 C13.67 26.35 13.34 24.7 13 23 C12.01 24.2375 12.01 24.2375 11 25.5 C6.97633382 28.85305515 5.09299533 28.43160977 0 28 C-0.61538462 20.73846154 -0.61538462 20.73846154 2.5 17 C3.325 16.34 4.15 15.68 5 15 C3.66671836 12.01517971 3.66671836 12.01517971 1.375 9.75 C-0.93690107 6.80758046 -0.1791891 3.64351176 0 0 Z "
        fill="#E1FCCC"
        transform="translate(10,10)"
      />
      <path
        d="M0 0 C6.77119591 -0.71275746 6.77119591 -0.71275746 9.87890625 1.08984375 C14.049714 4.60750487 17.5787649 8.76418511 21 13 C19.44805246 16.32759923 17.69378051 18.48580485 15 21 C12.04590299 19.62845496 9.55991624 18.01136276 7 16 C4.69 17.98 2.38 19.96 0 22 C1.61737724 18.76524552 2.47629223 17.29427979 5 15 C3.66671836 12.01517971 3.66671836 12.01517971 1.375 9.75 C-0.93690107 6.80758046 -0.1791891 3.64351176 0 0 Z "
        fill="#D7F3C5"
        transform="translate(10,10)"
      />
      <path
        d="M0 0 C3.36591866 1.44253657 5.51047079 3.33264727 8 6 C6.55746343 9.36591866 4.66735273 11.51047079 2 14 C-1.36591866 12.55746343 -3.51047079 10.66735273 -6 8 C-4.55746343 4.63408134 -2.66735273 2.48952921 0 0 Z "
        fill="#1B4446"
        transform="translate(23,17)"
      />
    </svg>
  );
}
