export function TypeformLightIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_630_83952)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 8.91273C3 6.99273 3.75818 6 5.02909 6C6.30545 6 7.06364 6.99273 7.06364 8.91273V14.7273C7.06364 16.6473 6.3 17.64 5.02909 17.64C3.75818 17.64 3 16.6473 3 14.7273V8.91273ZM16.68 6H12.48C8.71636 6 8.42182 7.62545 8.42182 9.79636L8.41636 13.8436C8.41636 16.1018 8.7 17.64 12.4964 17.64H16.68C20.4545 17.64 20.7327 16.02 20.7327 13.8545V9.80182C20.7327 7.62545 20.4436 6 16.68 6Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_630_83952">
          <rect width="18" height="12" fill="white" transform="translate(3 6)" />
        </clipPath>
      </defs>
    </svg>
  );
}
