export function TokenioLightIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_630_84099)">
        <path
          d="M7.05734 10.5C6.62206 10.5 6.19655 10.632 5.83463 10.8792C5.4727 11.1264 5.19062 11.4779 5.02404 11.889C4.85747 12.3002 4.81388 12.7526 4.8988 13.189C4.98372 13.6255 5.19333 14.0264 5.50112 14.3411C5.80891 14.6558 6.20106 14.8701 6.62798 14.9569C7.0549 15.0437 7.49741 14.9992 7.89956 14.8289C8.30171 14.6586 8.64543 14.3702 8.88726 14.0002C9.12909 13.6301 9.25817 13.1951 9.25817 12.7501C9.25817 12.4546 9.20124 12.162 9.09064 11.889C8.98004 11.616 8.81793 11.368 8.61356 11.159C8.4092 10.9501 8.16658 10.7844 7.89956 10.6713C7.63255 10.5582 7.34636 10.5 7.05734 10.5ZM7.05734 14.5913C6.58755 14.5832 6.14015 14.3847 5.81347 14.0394C5.48679 13.6941 5.30758 13.2304 5.31524 12.7501C5.30754 12.2697 5.48673 11.8059 5.81341 11.4606C6.14009 11.1153 6.58753 10.9168 7.05734 10.9087C7.52716 10.9168 7.97459 11.1153 8.30127 11.4606C8.62795 11.8059 8.80714 12.2697 8.79945 12.7501C8.8071 13.2304 8.62789 13.6941 8.30122 14.0394C7.97454 14.3847 7.52713 14.5832 7.05734 14.5913ZM2.76184 14.4559V11.4528H1.5V11.0441H4.47611V11.4528H3.21915V14.4561L2.76184 14.4559ZM12.6131 14.4559L11.5296 12.8853H10.4702V14.4559H10.0077V11.0441H10.47V12.4768H11.5631L12.5696 11.0441H13.0994L11.9199 12.7083L13.1382 14.4561L12.6131 14.4559ZM13.9 14.4559V11.0441H16.5197V11.4528H14.3623V12.4768H16.3656V12.8855H14.3623V14.0474H16.5582V14.4561L13.9 14.4559ZM20.5425 11.0441H21V14.4559H20.1332L18.0435 11.4528H17.8706V14.4561H17.4082V11.0441H18.2794L20.3743 14.0474H20.5429L20.5425 11.0441Z"
          fill="#1C0F36"
        />
      </g>
      <defs>
        <clipPath id="clip0_630_84099">
          <rect width="19.5" height="4.5" fill="white" transform="translate(1.5 10.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
