export function ShoplineLightIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.2923 9.13967C11.0796 9.13822 10.8713 9.19793 10.6937 9.31124C10.5161 9.42454 10.3774 9.58635 10.2949 9.77613C10.2125 9.9659 10.1901 10.1751 10.2306 10.3772C10.2712 10.5793 10.3729 10.7652 10.5227 10.9113C10.6726 11.0573 10.8639 11.157 11.0724 11.1978C11.2808 11.2385 11.4971 11.2183 11.6938 11.1399C11.8904 11.0614 12.0586 10.9282 12.1769 10.7572C12.2952 10.5861 12.3584 10.3849 12.3584 10.179C12.3584 9.90462 12.2463 9.64137 12.0466 9.44668C11.8469 9.25198 11.5757 9.14162 11.2923 9.13967Z"
        fill="#00142D"
      />
      <path
        d="M12.298 3C10.4587 3 8.66072 3.52804 7.13149 4.51729C5.60227 5.50655 4.41052 6.91259 3.70703 8.55755C3.00354 10.2025 2.81984 12.0125 3.17921 13.7585C3.53857 15.5046 4.42487 17.1082 5.72606 18.3666C7.02716 19.625 8.68456 20.4815 10.4887 20.828C12.2929 21.1744 14.1626 20.9951 15.8615 20.3128C17.5603 19.6305 19.0119 18.4757 20.0327 16.9947C21.0535 15.5137 21.5975 13.7729 21.596 11.9927C21.596 10.8111 21.3555 9.64111 20.8881 8.5496C20.4207 7.45808 19.7357 6.46641 18.8722 5.63127C18.0088 4.79614 16.9837 4.1339 15.8556 3.68241C14.7275 3.23093 13.5187 2.99903 12.298 3ZM10.4856 19.8575L9.13009 14.8157L13.4859 19.9754C13.0924 20.0286 12.6955 20.0557 12.298 20.0565C11.6881 20.0547 11.0804 19.988 10.4856 19.8575ZM8.96261 14.0713L17.0346 18.6339C16.0929 19.2611 15.0273 19.6929 13.9048 19.9017L8.96261 14.0713ZM17.3392 18.4201L8.84077 13.6217L7.51575 8.70516C7.47838 8.55961 7.50132 8.40569 7.57955 8.27625C7.65777 8.14681 7.78521 8.05209 7.93453 8.01229L12.9605 6.64865L20.4613 10.4595C20.5688 10.9763 20.6223 11.5022 20.6213 12.0295C20.613 13.2649 20.3089 14.4817 19.7328 15.5841C19.1567 16.6866 18.3243 17.6448 17.3011 18.3833L17.3392 18.4201Z"
        fill="#00142D"
      />
    </svg>
  );
}
