import styled, { css } from 'styled-components';

type Props = {
  center?: boolean;
  light?: boolean;
};

const FullscreenLayout = styled.div<Props>`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding-top: 120px;
  padding-bottom: 120px;
  box-sizing: border-box;
  background-color: ${({ theme, light }) =>
    light ? theme.colors.surface.base.surface : theme.colors.surface.base.background};
  ${({ center }) =>
    center &&
    css`
      align-items: center;
      justify-content: center;
      text-align: center;
    `}
`;

export default FullscreenLayout;
