export function PostmarkLightIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2" y="2" width="12" height="12" rx="2" fill="url(#pattern0_630_84041)" />
      <defs>
        <pattern
          id="pattern0_630_84041"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1">
          <use xlinkHref="#image0_630_84041" transform="scale(0.00555556)" />
        </pattern>
        <image
          id="image0_630_84041"
          width="180"
          height="180"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALQAAAC0CAMAAAAKE/YAAAAAXVBMVEX/3gDfwgCAbwCQfQCfiwC/pgDPtADv0AC/pwAAAAAQDgBANwBQRQCgiwAgHABgUwBfUwBwYQCQfABvYQAQDQB/bwCvmACwmQAwKgCgigCvmQCfigBPRQDewQD/3gDAdnn5AAAAH3RSTlP////////////////////////////////////////+lxypCwAAAc1JREFUeAHt2gV67DoMQGHJmdRRPOOUud3/Lt+7DNZcJunr+VdwwjEIXjQAAAAAAAAAAAAAWr7PtNvNJ1ViWOxHrG0/zTmiXflBE0R77VATRDt90wTR3qYJo60fEkR7e00Ybf0kQbQ3ZYy2kjHapozRNmeMXlX+gbqc2q9o8m+cfbVDdVnOL1qY2/qdeumjveW02zFrlX9iOx7t7I5ml2DRzpV5aw0eLdrNmaJHH6tu4aNFL22k4aPl2kZT/GhpNtgniF5ssCaIlm4DTRB9ZoM5QfSNDQ4JotUGU4JosUHJHU000TXjg7hkfOWdZ/y4NBvU+NGa8Yep2KDFj9aMg4CWcLhVbHQr0aOLOXfBo7WZp6Gja1nN2yRYtEv2ukaNrnNpdtyVhIjuu0+Usm233b6oS4zoH7Fqwuh7yRd9JfmiryRf9JWki14fJF30rUq26PVKJFv0ppIteptFckX3qyqSKvr2ahYvbnTfX1xXCcJH9+1TpZTz3bX63ljRTcIhmmiiiSaaaKKJJppoool+tM90ScDtHp0lvs0GXSWqqq+d7Jo5fZr1naenJwlkse9ENNFEE0000UQT/VKjtXwniQMAAAAAAAAAAAAAniP5HwUIl3mELjiAAAAAAElFTkSuQmCC"
        />
      </defs>
    </svg>
  );
}
