export function CourierLightIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 33 33" fill="none">
      <g filter="url(#a)">
        <g clipPath="url(#b)">
          <rect width="32" height="32" x=".77" y=".771" fill="#8F4570" rx="16" />
          <path fill="url(#c)" fillOpacity=".7" d="M-13.63-14.429h60.8v62.4h-60.8z" />
          <g fill="#fff" filter="url(#d)">
            <path d="M28.772 14.95a.343.343 0 0 0 .076-.283 12.071 12.071 0 0 0-4.598-7.498 12.236 12.236 0 0 0-8.496-2.43 12.205 12.205 0 0 0-7.912 3.917 12.036 12.036 0 0 0-3.154 8.2c.046 6.515 5.576 11.98 12.138 11.997a12.238 12.238 0 0 0 7.345-2.422 12.09 12.09 0 0 0 4.392-6.328.347.347 0 0 0-.22-.425l-.502-.185a6.697 6.697 0 0 0-5.855.683c-.786.508-1.373.94-1.373.94a6.22 6.22 0 0 1-3.474 1.055c-3.433 0-5.841-2.784-6.215-6.171l-.27-1.853a1.574 1.574 0 0 0-1.004-1.249l-.697-.266a.198.198 0 0 1-.124-.22.195.195 0 0 1 .037-.082c2.941-3.9 6.534-3.05 6.534-3.05.58.055 1.136.258 1.613.59a4.13 4.13 0 0 1 1.442 1.776 7.14 7.14 0 0 0 2.647 3.113 7.215 7.215 0 0 0 3.932 1.164s2.8.108 3.74-.973z" />
            <path d="M12.468 12.348c.392 0 .71-.315.71-.704a.707.707 0 0 0-.71-.704.707.707 0 0 0-.71.704c0 .389.318.704.71.704z" />
          </g>
        </g>
        <rect
          width="31.544"
          height="31.544"
          x=".998"
          y=".998"
          stroke="#2A3142"
          strokeOpacity=".09"
          strokeWidth=".455"
          rx="15.772"
        />
      </g>
      <defs>
        <filter
          id="a"
          width="32"
          height="32.8"
          x=".77"
          y=".771"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy=".8" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0.254167 0 0 0 0 0.0698958 0 0 0 0 0.213627 0 0 0 0.94 0" />
          <feBlend in2="shape" result="effect1_innerShadow_2035_6353" />
        </filter>
        <filter
          id="d"
          width="29.344"
          height="29.344"
          x="2.099"
          y="2.962"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy=".863" />
          <feGaussianBlur stdDeviation="1.295" />
          <feColorMatrix values="0 0 0 0 0.0941176 0 0 0 0 0.152941 0 0 0 0 0.294118 0 0 0 0.29 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2035_6353" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_2035_6353" result="shape" />
        </filter>
        <radialGradient
          id="c"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(-42.83609 -46.45355 45.40868 -41.87258 45.098 46.584)"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#521E65" />
          <stop offset=".971" stopColor="#8E5899" stopOpacity=".63" />
        </radialGradient>
        <clipPath id="b">
          <rect width="32" height="32" x=".77" y=".771" fill="#fff" rx="16" />
        </clipPath>
      </defs>
    </svg>
  );
}
