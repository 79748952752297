export function XLightIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_630_84014)">
        <path
          d="M13.427 10.8482L19.0111 4.5H17.6879L12.8392 10.0121L8.9666 4.5H4.5L10.3562 12.8352L4.5 19.4923H5.82333L10.9436 13.6714L15.0334 19.4923H19.5L13.4267 10.8482H13.427ZM11.6146 12.9087L11.0212 12.0787L6.30014 5.47426H8.33269L12.1427 10.8042L12.736 11.6342L17.6885 18.5623H15.656L11.6146 12.909V12.9087Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_630_84014">
          <rect width="15" height="15" fill="white" transform="translate(4.5 4.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
