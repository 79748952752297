export function PropertyFinderLightIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.99861 2C10.2068 2 12 3.79006 12 6C12 6.3453 11.9554 6.67956 11.8747 7C11.7828 7.35359 11.6436 7.69061 11.4654 8C11.2899 8.30387 11.0755 8.58287 10.8277 8.82873C10.1037 9.55249 9.10407 10 8.00139 10V9.94751C7.96241 8.80663 7.46954 7.78177 6.70101 7.04696C5.99373 6.37017 5.04699 5.93923 4.00557 5.8895C4.03341 4.82873 4.47616 3.87293 5.17508 3.17127C5.89349 2.44751 6.89314 2 7.99861 2ZM4 14C6.20814 14 8.00139 12.2099 8.00139 10H4V14Z"
        fill="#EF5E4E"
      />
    </svg>
  );
}
