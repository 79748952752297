export function PipedriveLightIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_630_84040)">
        <path
          d="M14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8Z"
          fill="#017737"
        />
        <path
          d="M11.2914 7.00089C11.2914 8.6242 10.244 9.73444 8.74638 9.73444C8.03441 9.73444 7.44764 9.44105 7.22795 9.07444L7.23834 9.45144V11.7763H5.70953V5.76499C5.70953 5.68137 5.68874 5.66011 5.59425 5.66011H5.07031V4.38263H6.34827C6.93456 4.38263 7.08149 4.8853 7.12307 5.10546C7.35362 4.71806 7.96071 4.26782 8.8196 4.26782C10.2965 4.26735 11.2914 5.36672 11.2914 7.00089ZM9.73094 7.01129C9.73094 6.142 9.16543 5.5453 8.45299 5.5453C7.86669 5.5453 7.20669 5.9327 7.20669 7.02215C7.20669 7.73412 7.60449 8.47774 8.4322 8.47774C9.03976 8.47727 9.73094 8.03743 9.73094 7.01129Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_630_84040">
          <rect width="12" height="12" fill="white" transform="translate(2 2)" />
        </clipPath>
      </defs>
    </svg>
  );
}
