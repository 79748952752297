export function AkeneoLightIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width="16"
      height="16"
      viewBox="0 0 1080 1080">
      <rect width="100%" height="100%" fill="transparent"></rect>
      <path
        fill="#994AFF"
        fillRule="evenodd"
        d="M178.533 5.961C84.515 5.961 8.298 82.178 8.298 176.197v723.498c0 94.019 76.217 170.236 170.235 170.236h723.5c94.018 0 170.236-76.217 170.236-170.236V176.197c0-94.02-76.218-170.236-170.236-170.236zm556.104 874.363q.007.044.013.085l.03-.053zm0 0c-2.266-14.31-2.701-42.31-.07-82.128 1.618-24.495 8.096-48.446 15.869-71.731 12.735-38.175 31.859-73.493 50.953-108.76q1.98-3.653 3.955-7.308l2.895-5.35c19.09-35.246 39.756-73.4 44.944-113.383 7.16-55.265-31.31-110.021-64.355-150.627-45.324-55.689-100.747-103.373-158.605-145.556 5.44 52.745 8.044 108.33 4.23 162.216-3.626 50.93-14.92 100.828-31.02 149.213a793 793 0 0 1-30.856 78.473c-3.692 8.093-4.036 8.299-12.902 7.47a792 792 0 0 1-20.426-2.187 795 795 0 0 1-42.908-6.335c-50.503-8.863-100.631-22.332-147.557-43.175-55.32-24.55-107.266-56.97-156.053-92.637 5.698 54.038 15.573 108.016 30.82 160.198 13.971 47.853 32.09 96.644 62.469 136.675 15.186 20.022 34.33 37.848 58.407 46.282 21.484 7.52 44.72 10.627 67.31 12.694 22.453 2.085 44.986 2.722 67.52 3.359 25.677.724 51.354 1.45 76.913 4.319 64.91 7.252 125.261 30.212 178.467 68.278"
        vectorEffect="non-scaling-stroke"></path>
    </svg>
  );
}
