import dark_theme from './dark';
import light_theme from './light';

const BASE_FONT_SIZE_IN_PX = 13;
const GRID_PX_SIZE = 4;

type PxToRem = (pixel: number) => string;
const curriedPxToRem =
  (base_font_size: number, is_shadow_node?: boolean): PxToRem =>
  (pixel) =>
    is_shadow_node ? `${pixel}px` : `${pixel / base_font_size}rem`;

export type SpacingGridMultiple =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 14
  | 18
  | 22
  | 26
  | 30;

const curriedSpacing =
  (pxToRem: PxToRem) =>
  (multiple: SpacingGridMultiple | number): string =>
    pxToRem(multiple * GRID_PX_SIZE);

const font_weigths = {
  bold: 600,
  medium: 500,
  regular: 400,
};

const radius = {
  xsmall_inset: '1px',
  xsmall: '2px',
  small_inset: '3px',
  small: '4px',
  normal_inset: '5px',
  normal: '6px',
  large_inset: '7px',
  large: '8px',
};

const breakpoints = [272, 418, 704, 848, 1472];

export interface ThemeOverrides {
  font_size?: number;
}

const buildTheme = (
  configs: { colors: typeof light_theme.colors; elevation: typeof light_theme.elevation },
  overrides = {} as ThemeOverrides,
  is_shadow_node?: boolean,
) => {
  const pxToRem = curriedPxToRem(overrides.font_size || BASE_FONT_SIZE_IN_PX, is_shadow_node);
  const spacing = curriedSpacing(pxToRem);
  return {
    ...configs,
    font_size: `${overrides.font_size || BASE_FONT_SIZE_IN_PX}px`,
    grid_size: GRID_PX_SIZE,
    radius,
    font_weigths,
    border: `1px solid ${configs.colors.outline.neutral}`,
    border_dashed: `1px dashed ${configs.colors.outline.neutral}`,
    breakpoints,
    // Utils
    getBreakpoint: (breakpoint) => {
      const sizes = ['xsmall', 'small', 'medium', 'large', 'xlarge'];
      return breakpoints[sizes.indexOf(breakpoint)];
    },
    spacing,
    pxToRem,
    optionsToCss: <T>(options: Record<string, any>, props: T, fallback?: string): any => {
      const key = Object.keys(options).find((k) => props[k]) || fallback!;
      return options[key];
    },
    reactDatepicker: {
      daySize: [32, 32],
      fontFamily: 'Inter, sans-serif',
      datepickerBoxShadow: 'none',
      navButtonBorder: 'none',
      datepickerPadding: '0px',
      datepickerBorderRadius: '0px',
      closeColor: configs.colors.on.neutral.primary_neutral,
      closeHoverColor: configs.colors.on.hue_container.primary,
      datepickerBackground: configs.colors.surface.base.surface,

      daySelectedColor: configs.colors.on.hue_container.primary,
      daySelectedFirstOrLastColor: configs.colors.on.hue_container.primary,
      daySelectedHoverColor: configs.colors.on.hue_container.primary,
      daySelectedHoverBackground: configs.colors.surface.container.hover.primary,
      daySelectedFirstOrLastBackground: configs.colors.surface.container.primary,
      daySelectedBorderColor: configs.colors.outline.primary,
      daySelectedFirstOrLastBorderColor: configs.colors.outline.primary,

      dayBorderColor: configs.colors.outline.neutral,
      dayHoverColor: configs.colors.on.hue_container.primary,
      dayHoverRangeColor: configs.colors.surface.container.primary,
      dayHoverRangeBorderColor: configs.colors.outline.primary,
      dayHoverRangeBackground: configs.colors.surface.container.primary,

      colors: {
        white: configs.colors.surface.base.surface,
        darcula: configs.colors.on.neutral.primary_neutral,
        mud: configs.colors.on.neutral.secondary_neutral,
        accessibility: configs.colors.on.neutral.primary,
        selectedDay: configs.colors.surface.container.primary,
        selectedDayHover: configs.colors.surface.container.primary,
        primaryColor: configs.colors.on.hue_container.primary,
        normalDayHover: configs.colors.surface.container.primary,
      },
    },
    zindex: {
      sticky: 1000,
      fixed: 1020,
      dropdown: 1030,
      screen_modal: 1050,
      modal_backdrop: 1059,
      modal: 1060,
      popover: 1070,
      tooltip: 1080,
    },
  };
};

const getThemes = (overrides?: ThemeOverrides, is_shadow_node?: boolean) => ({
  light: buildTheme(light_theme, overrides, is_shadow_node),
  dark: buildTheme(dark_theme, overrides, is_shadow_node),
});

const default_themes = getThemes();

export type ThemeModes = keyof typeof default_themes | 'system';
export type Theme = typeof default_themes.light & { selected: ThemeModes };

export default getThemes;
