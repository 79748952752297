import 'react';
import styled, { css } from 'styled-components';
import { getStyledUtils, StyledUtilsProps } from '../helpers/StyledUtils';

const size_configs = {
  '2xl': {
    font_size: 24,
    line_height: 32,
  },
  xl: {
    font_size: 20,
    line_height: 28,
  },
  l: {
    font_size: 16,
    line_height: 24,
  },
  m: {
    font_size: 14,
    line_height: 20,
  },
  s: {
    font_size: 12,
    line_height: 20,
  },
  xs: {
    font_size: 10,
    line_height: 16,
  },
} as const;

const type_configs = {
  heading: {
    weight: 'bold',
  },
  subtitle: {
    weight: 'medium',
  },
  body: {
    weight: 'regular',
  },
  monospace: {
    weight: 'regular',
  },
} as const;

interface Props extends StyledUtilsProps {
  as?: string;

  size?: keyof typeof size_configs;

  heading?: boolean;
  subtitle?: boolean;
  body?: boolean;
  monospace?: boolean;

  muted?: boolean;
  danger?: boolean;
  success?: boolean;
  primary?: boolean;
  warning?: boolean;
  dark?: boolean;

  underline?: boolean;
  strike?: boolean;
  uppercase?: boolean;
  capitalize?: boolean;
  text_wrap?: boolean | string;
  overflow_wrap?: string;
  word_break?: string;
  center?: boolean;
  align?: string;
  white_space?: string;
  ellipsis?: boolean;
  max_width?: string;
  user_select?: boolean;
  pointer?: boolean;
  cursor_default?: boolean;
}

const Text = styled.div<Props>(
  ({
    theme,
    size = 'm',
    // Text types
    heading,
    subtitle,
    body,
    monospace,

    muted,
    danger,
    primary,
    warning,
    dark,
    success,

    uppercase,
    strike,
    capitalize,
    text_wrap,
    overflow_wrap,
    word_break,
    center,
    align,
    white_space,
    ellipsis,
    max_width,

    user_select = true,
    pointer = false,
    cursor_default = false,
    ...props
  }) => {
    let type = 'body';
    if (heading) {
      type = 'heading';
    } else if (subtitle) {
      type = 'subtitle';
    } else if (body) {
      type = 'body';
    } else if (monospace) {
      type = 'monospace';
    }

    return css`
      ${getStyledUtils({ theme, ...props })}

      ${type === 'monospace' &&
      css`
        font-family:
          JetBrains Mono,
          monospace;
      `};

      font-weight: ${theme.font_weigths[type_configs[type].weight]};
      font-size: ${theme.pxToRem(size_configs[size].font_size)};
      line-height: ${theme.pxToRem(size_configs[size].line_height)};

      ${muted &&
      css`
        color: ${theme.colors.on.neutral.secondary_neutral};
      `};

      ${dark &&
      css`
        color: ${theme.colors.on.neutral.primary_neutral};
      `};

      ${danger &&
      css`
        color: ${theme.colors.on.neutral.danger};
      `};

      ${primary &&
      css`
        color: ${theme.colors.on.neutral.primary};
      `};

      ${warning &&
      css`
        color: ${theme.colors.on.neutral.warning};
      `};

      ${success &&
      css`
        color: ${theme.colors.on.neutral.success};
      `};

      ${center &&
      css`
        text-align: center;
      `};
      ${text_wrap === false &&
      css`
        white-space: nowrap;
      `};
      ${text_wrap &&
      css`
        white-space: ${typeof text_wrap === 'string' ? text_wrap : 'wrap'};
      `};
      ${white_space &&
      css`
        white-space: ${white_space};
      `};
      ${uppercase &&
      css`
        text-transform: uppercase;
      `};
      ${strike &&
      css`
        text-decoration: line-through;
      `};
      ${capitalize &&
      css`
        text-transform: capitalize;
      `};
      ${align &&
      css`
        text-align: ${align};
      `};
      ${overflow_wrap &&
      css`
        overflow-wrap: ${overflow_wrap};
      `};
      ${word_break &&
      css`
        word-break: ${word_break};
      `};
      ${ellipsis &&
      css`
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      `};
      ${max_width &&
      css`
        max-width: ${max_width};
      `};
      ${!user_select &&
      css`
        user-select: none;
        pointer-events: none;
      `};
      ${pointer &&
      css`
        cursor: pointer;
      `};
      ${cursor_default &&
      css`
        cursor: default;
      `};
    `;
  },
);

export default Text;
