export function LinearLightIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_630_84129)">
        <path
          d="M2.14705 9.38273C2.12035 9.26891 2.25595 9.19723 2.33861 9.27989L6.7201 13.6614C6.80277 13.744 6.73108 13.8796 6.61726 13.8529C4.40618 13.3343 2.66573 11.5938 2.14705 9.38273ZM2.00023 7.62669C1.99811 7.66069 2.01089 7.69388 2.03498 7.71796L8.28204 13.965C8.30612 13.9891 8.33931 14.0019 8.37331 13.9998C8.65761 13.9821 8.93656 13.9446 9.2088 13.8887C9.30054 13.8698 9.33241 13.7571 9.26618 13.6909L2.30911 6.73382C2.24289 6.66759 2.13017 6.69946 2.11133 6.7912C2.05542 7.06343 2.01793 7.34239 2.00023 7.62669ZM2.50531 5.56465C2.48533 5.6095 2.49551 5.66192 2.53023 5.69665L10.3034 13.4698C10.3381 13.5045 10.3905 13.5147 10.4354 13.4947C10.6497 13.3992 10.8574 13.2916 11.0576 13.1726C11.1239 13.1332 11.1341 13.0422 11.0796 12.9877L3.01228 4.9204C2.95779 4.86591 2.86675 4.87613 2.82739 4.94239C2.70843 5.14259 2.60078 5.35031 2.50531 5.56465ZM3.51904 4.16888C3.47463 4.12447 3.47188 4.05324 3.51373 4.00639C4.61354 2.77512 6.21337 2 7.99423 2C11.3111 2 14 4.68887 14 8.00577C14 9.78663 13.2249 11.3865 11.9936 12.4863C11.9468 12.5281 11.8755 12.5254 11.8311 12.481L3.51904 4.16888Z"
          fill="#6D78D5"
        />
      </g>
      <defs>
        <clipPath id="clip0_630_84129">
          <rect width="12" height="12" fill="white" transform="translate(2 2)" />
        </clipPath>
      </defs>
    </svg>
  );
}
