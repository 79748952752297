export function TelnyxLightIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.682 19.3367C20.8735 18.9921 20.9795 18.6066 20.9911 18.2125C21.0028 17.8184 20.9197 17.4273 20.7489 17.0719C20.7259 17.0209 20.6997 16.9713 20.6705 16.9235L18.2787 12.4524H15.8325L18.3341 17.1383C18.4124 17.2742 18.4537 17.4283 18.4537 17.5852C18.4537 17.7421 18.4124 17.8962 18.3341 18.0321C18.2574 18.1598 18.1487 18.2653 18.0189 18.3384C17.8888 18.4116 17.7419 18.4503 17.5924 18.4503H14.739C14.7073 18.8871 14.5703 19.3098 14.3397 19.6822C14.1082 20.056 13.7904 20.3688 13.413 20.5943H18.6576C19.0074 20.5714 19.348 20.4727 19.656 20.3053C20.0889 20.0948 20.4469 19.7568 20.682 19.3367ZM7.26927 9.52741H9.71546L10.9349 7.28193C11.0384 7.08853 11.1931 6.9265 11.3823 6.81307C11.5722 6.69967 11.7892 6.63979 12.0103 6.63979C12.2315 6.63979 12.4485 6.69967 12.6383 6.81307C12.8281 6.92598 12.9828 7.08801 13.0858 7.28193L14.2697 9.52741H16.7159L14.98 6.27836C14.691 5.74044 14.2609 5.29141 13.736 4.97948C13.2087 4.66565 12.6064 4.5 11.9928 4.5C11.3792 4.5 10.777 4.66565 10.2496 4.97948C9.72479 5.29129 9.29474 5.74013 9.00564 6.27784L7.26927 9.52688V9.52741ZM9.24242 12.0619C9.26804 11.6276 9.39923 11.2052 9.62556 10.832C9.8523 10.458 10.1661 10.1444 10.5403 9.91786H5.80469V12.0619H9.24242Z"
        fill="#00E3AA"
      />
      <path
        d="M9.64844 12.2181V18.0749H10.7612C10.9928 18.0725 11.2174 17.9949 11.401 17.8538C11.5272 17.7568 11.6296 17.6325 11.7006 17.4902C11.7716 17.3478 11.8093 17.1912 11.8108 17.0321V13.5175C11.8119 13.136 11.965 12.7701 12.2368 12.4999C12.5098 12.2288 12.8782 12.0756 13.2628 12.0728H18.2153V9.91724H11.9692C11.3546 9.91771 10.7649 10.1603 10.3279 10.5926C10.1129 10.8051 9.94216 11.0581 9.82553 11.3371C9.7089 11.616 9.64871 11.9158 9.64844 12.2181Z"
        fill="#00E3AA"
      />
      <path
        d="M3.24342 17.072C3.17024 17.2277 3.1117 17.3898 3.06989 17.5565C2.9233 18.1604 3.00912 18.7974 3.31033 19.341C3.5461 19.7594 3.90409 20.0959 4.33637 20.3053C4.64371 20.4726 4.9845 20.5714 5.33471 20.5944H12.0006C12.6272 20.5949 13.2288 20.3483 13.6748 19.9081C13.8941 19.6915 14.0683 19.4335 14.1873 19.1491C14.3063 18.8646 14.3676 18.5595 14.3678 18.2512V12.4482H13.2394C12.9623 12.4556 12.6984 12.569 12.5039 12.765C12.3098 12.9602 12.1999 13.2236 12.1976 13.4989V17.0134C12.197 17.2028 12.1588 17.3901 12.0853 17.5646C12.0118 17.7391 11.9045 17.8973 11.7696 18.0301C11.4954 18.2998 11.126 18.4508 10.7414 18.4503H6.41981C6.27032 18.4503 6.12345 18.4116 5.9933 18.3384C5.86354 18.2655 5.75494 18.1602 5.67811 18.0327C5.59976 17.896 5.55853 17.7412 5.55853 17.5837C5.55853 17.4261 5.59976 17.2714 5.67811 17.1347L8.18023 12.4482H5.71366L3.32235 16.9199C3.29464 16.9742 3.26694 17.0213 3.24342 17.072Z"
        fill="#00E3AA"
      />
    </svg>
  );
}
