import styled, { css } from 'styled-components';

import { Div, StyledUtilsProps } from '../helpers/StyledUtils';

interface Props extends StyledUtilsProps {
  raised?: true | number;
  muted?: boolean;
  borderless?: boolean;
  danger?: boolean;
  dashed?: boolean;
  overflow_hidden?: true;
  separator?: boolean;
}

export const StyledCardSection = styled(Div)<{
  muted?: boolean;
  borderless?: boolean;
  danger?: boolean;
  highlight?: boolean;
  scroll?: boolean;
}>(
  ({ theme, muted, borderless = false, danger, highlight, scroll }) => css`
    max-width: 100%;

    ${borderless &&
    css`
      border: none !important;
    `}

    ${scroll &&
    css`
      overflow-y: scroll;
      ::-webkit-scrollbar {
        display: none;
      }
    `}

    &:first-of-type {
      border-radius: ${theme.radius.normal_inset} ${theme.radius.normal_inset} 0 0;
    }
    &:last-of-type {
      border-radius: 0 0 ${theme.radius.normal_inset} ${theme.radius.normal_inset};
    }
    ${muted &&
    css`
      background-color: ${theme.colors.surface.base.variant_surface_2};
    `};

    ${danger &&
    css`
      background-color: ${theme.colors.surface.container.danger};
    `};
    ${highlight &&
    css`
      &:last-of-type {
        border-radius: 0 0 ${theme.radius.normal_inset} ${theme.radius.normal_inset};
      }
      background-color: ${theme.colors.surface.base.background};
    `};
  `,
);

export const StyledCard = styled(Div)<Props>(
  ({
    theme,
    raised,
    muted,
    borderless = false,
    danger,
    dashed = false,
    overflow_hidden,
    separator = true,
  }) => css`
    width: 100%;
    text-align: left;
    box-sizing: border-box;
    background-color: ${muted
      ? theme.colors.surface.base.background
      : theme.colors.surface.base.surface};
    border-radius: ${theme.radius.normal};
    border: ${(muted && !dashed) || borderless
      ? 'none'
      : dashed
        ? theme.border_dashed
        : theme.border};
    box-shadow: ${raised && (raised === true || theme.elevation[raised])
      ? theme.elevation[raised === true ? 1 : raised]
      : 'none'};
    ${overflow_hidden &&
    css`
      overflow: hidden;
    `};
    ${StyledCardSection}:not(:first-child) {
      border-top: ${separator ? theme.border : 'none'};
    }

    ${danger &&
    css`
      border: 1px solid ${theme.colors.outline.danger};
      ${StyledCardSection}:last-child {
        border-top: 1px solid ${theme.colors.outline.danger};
      }
    `}
  `,
);
