export function MailgunLightIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0713 8.64345C11.7165 8.64345 11.4284 8.35541 11.4284 8.00062C11.4284 6.10673 9.89285 4.57258 8 4.57258C6.10715 4.57258 4.57161 6.10796 4.57161 8.00062C4.57161 9.89327 6.10715 11.4287 8 11.4287C9.30806 11.4287 10.4455 10.6968 11.0229 9.61883C11.7696 10.1034 12.7797 10.0181 13.435 9.36293C13.8121 8.98589 14 8.49263 14 7.99938C14 4.68631 11.3134 2 8 2C4.68659 2 2 4.68755 2 8.00062C2 11.3137 4.68659 14 8 14C9.65671 14 11.1576 13.3287 12.2431 12.2421L11.3344 11.3335C10.4814 12.1865 9.30311 12.7143 8.00124 12.7143C5.39749 12.7143 3.28704 10.6041 3.28704 8.00062C3.28704 5.39714 5.39625 3.28691 8 3.28691C10.6038 3.28691 12.7142 5.39714 12.7142 8.00062C12.7142 8.35541 12.4261 8.64345 12.0713 8.64345ZM8 10.143C6.81681 10.143 5.85741 9.18368 5.85741 8.00062C5.85741 6.81755 6.81681 5.85825 8 5.85825C9.18319 5.85825 10.1426 6.81755 10.1426 8.00062C10.1426 9.18368 9.18319 10.143 8 10.143Z"
        fill="#EB5454"
      />
    </svg>
  );
}
