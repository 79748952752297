export function RepayLightIcon() {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="16"
      height="16"
      viewBox="0 0 180 180"
      enableBackground="new 0 0 180 180"
      xmlSpace="preserve">
      <path
        fill="#93C74E"
        opacity="1.000000"
        stroke="none"
        d="
M22.808363,143.815552 
	C40.467407,105.158859 57.985069,66.858765 75.458557,28.538527 
	C77.578773,23.888786 80.200485,20.124557 86.009956,20.095909 
	C91.391266,20.069376 94.537308,22.853661 96.669701,27.575054 
	C105.235611,46.541080 114.298996,65.292908 122.400215,84.452980 
	C125.477264,91.730476 129.075638,95.329659 137.345642,94.219673 
	C143.398422,93.407303 149.649750,93.993912 155.810486,94.090843 
	C161.034012,94.173019 163.853455,96.626282 163.942307,100.896194 
	C164.035263,105.362457 161.112808,107.905144 155.621536,107.952957 
	C148.641907,108.013748 141.661362,107.967682 133.659271,107.967682 
	C135.382812,111.998123 136.772919,115.422821 138.300003,118.785301 
	C142.016922,126.969566 145.818466,135.115372 149.545044,143.295288 
	C152.132263,148.974274 151.058167,153.247482 146.611328,155.293564 
	C142.528015,157.172379 138.031143,154.798828 135.609589,149.417145 
	C129.940521,136.818161 124.216164,124.242729 118.713684,111.571144 
	C117.533386,108.853065 116.061745,107.828590 113.113556,107.910561 
	C106.124046,108.104897 99.125298,107.993462 92.130478,107.962807 
	C86.005943,107.935974 83.086739,105.742096 83.003723,101.191437 
	C82.918312,96.509171 86.029793,94.109413 92.348885,94.069984 
	C98.127823,94.033928 103.907166,94.062340 110.786484,94.062340 
	C102.497780,75.518967 94.593796,57.836266 86.116478,38.870926 
	C83.493912,44.443943 81.277596,48.948631 79.233612,53.530201 
	C65.058922,85.302666 50.982510,117.119324 36.655712,148.822906 
	C35.459583,151.469803 33.024178,154.227707 30.467710,155.358139 
	C28.698038,156.140640 25.241383,154.822891 23.406591,153.357056 
	C20.356535,150.920319 21.249046,147.387177 22.808363,143.815552 
z"
      />
    </svg>
  );
}
